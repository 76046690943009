import React, { useEffect, useRef, useState } from "react"
import { Dropdown, ValidatedInput } from '../../baseComponents'
import { ActiveQuestionProps, ErrorMsgsProps, FirstErrorProps, NUMBER_OF_SECURITY_QUESTIONS } from "./SetSecurityQuestions"

interface SecurityQuestionProps {
  options: Array<ActiveQuestionProps>
  selectedQuestions: Array<ActiveQuestionProps>
  questionNumber: number,
  question: string
  setQuestion: Function
  answer: string
  setAnswer: Function
  errorMsgs: Array<ErrorMsgsProps>
  setErrorMsgs: Function
  errorFocus: FirstErrorProps
  clearErrorFocus: Function
}

export const SecurityQuestion = (props: SecurityQuestionProps) => {
  const { options, selectedQuestions, questionNumber, question, setQuestion, answer, setAnswer, errorMsgs, setErrorMsgs, errorFocus, clearErrorFocus } = props

  const [textOptions, setTextOptions] = useState(options.map((opt) => opt.question))
  const questionRef = useRef<HTMLButtonElement>(null)
  const answerRef = useRef<HTMLDivElement>(null)

  // Handles where to put the focus when there are multiple validation errors. It sets the focus on the
  // question or answer input field IF this instance of SecurityQuestion is the first one that contains a validation error
  useEffect(() => {
    if (errorFocus.index === questionNumber && errorFocus.type === 'question') {
      questionRef.current!.focus()
      clearErrorFocus()
    }
    if (errorFocus.index === questionNumber && errorFocus.type === 'answer') {
      answerRef.current!.focus()
      clearErrorFocus()
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorFocus.index, errorFocus.type, questionNumber])

  // Handles removing items from the list of options which have already been selected in other instances of SecurityQuestion
  useEffect(() => {
    const updateOptions: Array<string> = []
    options.forEach((opt) => {
      // only display questions which have NOT been selected
      if (!selectedQuestions.includes(opt)) {
        updateOptions.push(opt.question)
      }
      // include the selected question for the current question in the list of options
      if (opt.question === question) {
        updateOptions.push(opt.question)
      }
    })
    setTextOptions(updateOptions)
  }, [options, question, selectedQuestions])


  const clearDropdownError = () => {
    if (errorMsgs[questionNumber].question !== '') setErrorMsgs(questionNumber, 'question', '')
  }

  const handleSelectQuestion = (questionIdx: number, value: string, ddIndex: number) => {
    const selectedOption = options.find(opt => opt.question === value)
    setQuestion(questionIdx, selectedOption)
  }

  const handleAnswerChange = (e: any) => {
    if (errorMsgs[questionNumber].answer !== '') setErrorMsgs(questionNumber, 'answer', '')
    setAnswer(e, questionNumber)
  }

  return (
    <>
      <h2 className="pt-4 pb-2 text-xl">Question {questionNumber + 1} of {NUMBER_OF_SECURITY_QUESTIONS}</h2>

      <div className={errorMsgs[questionNumber].question !== '' ? "box-content sticky border-l-4 pl-2 border-dhs-error-red font-semibold" : "h-76 font-semibold"} >
        <h3 className='text-md' data-testid="question-label">
          {`Security Question ${questionNumber + 1}`}
          <span className='text-dhs-error-red pl-1'>*</span>
        </h3>
        {(errorMsgs[questionNumber].question !== '') && <div className="text-dhs-error-red m-1 p-0" data-testid='question-error-message'>{errorMsgs[questionNumber].question}</div>}
        <div className={errorMsgs[questionNumber].question !== '' ? "border-3 border-dhs-error-red rounded focus:outline-5 focus:ring-4" : "my-2"} data-testid='question-dropdown'>
          <Dropdown
            label={`Security Question ${questionNumber + 1} required`}
            index={questionNumber}
            initialValue={question !== '' ? question : 'Select One'}
            options={textOptions}
            onSelect={handleSelectQuestion}
            errorMsg={errorMsgs[questionNumber].question}
            clearErrorMsg={clearDropdownError}
            ref={questionRef}
          />
        </div>
      </div>

      <div data-testid="security-answer-input" className="h-32 pt-4">
        <h3 className='text-md pt-2' data-testid="answer-label">
          {`Security Answer ${questionNumber + 1}`}
          <span className='text-dhs-error-red pl-1'>*</span>
        </h3>
        <ValidatedInput
          label={`Security Answer ${questionNumber + 1}`}
          required
          msg={errorMsgs[questionNumber].answer}
          onChange={(e) => handleAnswerChange(e)}
          value={answer}
          type='text'
          ref={answerRef}
          hideLabel
        />
      </div>
    </>
  )
}

