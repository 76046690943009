import React, { useEffect, useRef, useState, forwardRef } from 'react'
import { DropdownExpandIcon, DropdownCollapseIcon } from '../../../assets/icons'
import FocusTrap from 'focus-trap-react'

import './Dropdown.css'
interface DropdownProps {
  label: string
  initialValue?: string
  index: number
  options: Array<string>
  onSelect: Function
  clearErrorMsg?: Function
  errorMsg: string
}

const Dropdown = (props: DropdownProps, ref: any) => {
  const {
    label,
    options,
    index,
    initialValue = 'Select an item',
    onSelect,
    clearErrorMsg,
    errorMsg,
  } = props

  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [selected, setSelected] = useState<string | number | null>(initialValue)
  const [selectedIndex, setSelectedIndex] = useState<number>(0)
  const [focusIndex, setFocusIndex] = useState<number>(0)
  const [label508Text, setLabel508Text] = useState<string>('')

  const focusSelected = useRef<HTMLButtonElement>(null)
  const focusItem = useRef<Array<HTMLLIElement | null>>([])

  const toggleDD = () => {
    clearErrorMsg && clearErrorMsg()
    setIsOpen(!isOpen)
  }

  const selectItem = (val: string | number | null) => {
    setSelected(val)
    onSelect(index, val)
    setIsOpen(false)
  }

  useEffect(() => {
    if (isOpen) {
      const defaultSelection = options.indexOf(initialValue)
      setSelectedIndex(defaultSelection)

      const initialSelection = defaultSelection === -1 ? 0 : defaultSelection
      setFocusIndex(initialSelection)
      focusItem.current[initialSelection]?.focus()
    }
  }, [initialValue, isOpen, options])

  useEffect(() => {
    if (errorMsg !== '') {
      setLabel508Text(errorMsg)
    } else {
      setLabel508Text(`${isOpen ? 'expanded ' : 'collapsed'} ${selected}`)
    }
  }, [errorMsg, isOpen, selected])

  const keyDownHandler = (e: any) => {
    e.preventDefault()
    if (e.key === 'Escape') {
      setIsOpen(false)
    }
    if (e.key === 'Enter' && !isOpen) {
      setIsOpen(true)
    } else if (e.key === 'Enter' && isOpen) {
      selectItem(e.target.firstChild.data)
    }
    if (e.key === 'Tab' || e.key === 'ArrowDown') {
      const nextItem = focusIndex < options.length - 1 ? focusIndex + 1 : 0
      setFocusIndex(nextItem)
      focusItem.current[nextItem]?.focus()
    }
    if ((e.key === 'Tab' && e.shiftKey) || e.key === 'ArrowUp') {
      const nextItem = focusIndex === 0 ? options.length - 1 : focusIndex - 1
      setFocusIndex(nextItem)
      focusItem.current[nextItem]?.focus()
    }
  }

  return (
    <div data-testid="dropdown-container">
      {!isOpen ? (
        <button
          ref={ref}
          className="w-full p-2 border border-gray-300 rounded focus:outline-5 focus:ring-4"
          type="button"
          id="dropdown-toggle-button"
          aria-label={`${label} - ${label508Text}`}
          onClick={toggleDD}
        >
          <div
            className="flex justify-between"
            data-testid="dropdown-toggle-button"
          >
            <p className="text-left">{selected}</p>
            <DropdownExpandIcon color="#006699" />
          </div>
        </button>
      ) : (
        <FocusTrap
          focusTrapOptions={{
            clickOutsideDeactivates: true,
            onDeactivate: () => {
              setIsOpen(false)
            },
          }}
        >
          <div>
            <button
              ref={focusSelected}
              className="w-full p-2 border border-gray-500 rounded focus:outline-5 focus:ring-4"
              type="button"
              id="dropdown-toggle-button"
              aria-label={`${label} - ${label508Text}`}
              onClick={toggleDD}
            >
              <div
                className="flex justify-between"
                data-testid="dropdown-toggle-button"
              >
                <p className="text-left">{selected}</p>
                <DropdownCollapseIcon color="#006699" />
              </div>
            </button>
            <div
              className="dropdown_selection_box"
              data-testid="dropdown-options"
            >
              <ul
                className="dropdown_menu w-full pl-1 bg-white text-base z-50 float-left list-none text-left shadow-lg bg-clip-padding"
                aria-labelledby="dropdownMenuMediumButton"
              >
                {options.map((option, idx) => (
                  <li
                    key={`${option}-${idx}`}
                    tabIndex={0}
                    ref={(el) => (focusItem.current[idx] = el)}
                    data-testid="dropdown-links"
                    className={`w-full cursor-pointer flex align-left p-2 focus:outline-none border hover:bg-gray-20 ${
                      selectedIndex === idx && 'bg-light_blue-20'
                    } ${focusIndex === idx && 'bg-gray-20 border-dhs-blue-05'}`}
                    onClick={() => selectItem(option)}
                    onKeyDown={(e) => keyDownHandler(e)}
                    aria-label={option}
                  >
                    {option}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </FocusTrap>
      )}
    </div>
  )
}

export default forwardRef(Dropdown)
