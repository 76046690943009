import React, { useContext, useEffect, useRef, useState } from 'react'
import { Button, Modal, ValidatedInput } from '../../../baseComponents'
import {
  formatPhoneNumber,
  validateMobileFormat,
} from '../../../../utils/utils'
import { myAccountContext, pathContext } from '../../../../contexts/contexts'
import { useApiClient } from '../../../../hooks/useApiClient'
import PageTitleHook from '../../../../utils/PageTitleHook/PageTitleHook'

const EditMobile = () => {
  PageTitleHook('USCIS Online Account | Mobile phone')
  const { user, setUser, setAlert } = useContext(myAccountContext)
  const { setUrl } = useContext(pathContext)

  const [inputValidationMsg, setInputValidationMsg] = useState('')
  const [inputValue, setInputValue] = useState('')
  const [currentPhone, setCurrentPhone] = useState('')
  const [openModal, setOpenModal] = useState(false)
  const [disabledSubmit, setDisabledSubmit] = useState(false)

  const inputRef = useRef<HTMLInputElement>(null)

  useEffect(() => {
    setCurrentPhone(formatPhoneNumber(user.mobile))
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const apiClient = useApiClient()

  const handleSubmit = () => {
    let val = inputValue === null ? '' : inputValue.match(/[0-9]/g)?.join('')
    const validationMsg = validateMobileFormat(val)
    setInputValidationMsg(validationMsg)
    if (validationMsg !== '' && inputRef.current) {
      inputRef.current!.focus()
      return
    }

    setDisabledSubmit(true)
    apiClient
      .post('/users/set_mobile_number', { mobile: val })
      .then((res) => {
        setUser(res.data)
        setUrl('/change-mobile/auth')
      })
      .catch((err) => {
        setUrl('/edit-account')
      })
  }
  const handleDeleteModal = () => {
    setOpenModal(true)
  }

  const handleDeleteModalCancel = () => {
    setOpenModal(false)
  }

  const handleDeleteMobile = () => {
    setOpenModal(false)
    apiClient
      .post('/users/delete_mobile_number')
      .then(async (res) => {
        if (res.status === 200) {
          if (
            user.two_factor_method[0] !== res.data.two_factor_method[0] &&
            res.data.two_factor_method[0] === 'Email'
          ) {
            setAlert({
              type: 'success',
              message:
                'Mobile number was deleted. Two step verification changed to email.',
              shouldPersist: true
            })
          } else {
            setAlert({
              type: 'success',
              message: 'Mobile number was deleted.',
              shouldPersist: true
            })
          }
          setUser(res.data)
        } else if (res.status === 401) {
          setAlert({
            type: 'error',
            message: res.data.error,
          })
        }
      })
      .then(() => {
        setUrl('/edit-account')
      })
      .catch((err) => {
        setInputValidationMsg(err.message)
        inputRef.current!.focus()
      })
  }

  const handleCancel = () => {
    setUrl('/edit-account')
  }

  const handleInput = (e: any) => {
    if (inputValidationMsg !== '') setInputValidationMsg('')
    if (disabledSubmit) setDisabledSubmit(false)

    const value = e.target.value
    if (value === '(0__) ___-____' || value === '(1__) ___-____') {
      setInputValue('')
    } else {
      setInputValue(formatPhoneNumber(e.target.value))
    }
  }

  const getMobileNumber = (mobile: string) => {
    return mobile !== null ? mobile.replace('+1 ', '') : ''
  }

  useEffect(() => {
    const mobileNumber = getMobileNumber(user.mobile ? user.mobile : '')
    setInputValue(mobileNumber)
  }, [user.mobile])

  const handleEnterDown = (e: any) => {
    if (e.key === 'Enter') {
      handleSubmit()
    }
  }

  return (
    <div className="card mx-auto" data-testid="edit-mobile-container">
      <Modal
        title="Delete Mobile Phone Number"
        visible={openModal}
        onCancel={handleDeleteModalCancel}
        onOk={handleDeleteMobile}
        info={false}
      >
        <div className="flex flex-col space-y-2">
          <hr />
          <p className="py-2">
            Are you sure you want to delete the mobile <b>{currentPhone}</b>?
          </p>
        </div>
      </Modal>
      <h1 className="text-2xl pb-4 font-normal text-dhs_font_gray">
        Mobile Phone
      </h1>
      <hr className="pt-4" />

      <div className="flex flex-col space-y-4">
        <p className="py-2">
          If you have selected SMS as your two step verification method, this
          phone will receive your verification code when you confirm it.
        </p>
        <ValidatedInput
          label="Mobile Number"
          required
          msg={inputValidationMsg}
          onChange={handleInput}
          value={inputValue}
          type="text"
          inputMode="numeric"
          ref={inputRef}
          onKeyDown={handleEnterDown}
          mobile={true}
        />
        <p>
          If you select to receive text messaging notification to a U.S. mobile
          phone number listed in your account and accept these terms and
          conditions, you acknowledge that Standard Messaging Rates or other
          charges related to these notifications may apply.
        </p>
        <p>
          USCIS advises that you read the privacy policies of any application
          you use, especially if you share any personal information. USCIS is
          not responsible for the information collection practices of non-USCIS
          applications.
        </p>
      </div>
      <div className="button-container pt-6 pb-0">
        <Button
          id="submit-btn"
          data-testid="submit-btn"
          text="Submit"
          type="primary"
          disabled={disabledSubmit}
          onClick={handleSubmit}
        />
        {user.mobile !== null && (
          <Button
            id="delete-btn"
            data-testid="delete-btn"
            text="Delete"
            type="primary"
            onClick={handleDeleteModal}
          />
        )}
        <Button
          id="cancel-btn"
          data-testid="cancel-btn"
          text="Cancel"
          type="secondary"
          onClick={handleCancel}
        />
      </div>
    </div>
  )
}
export default EditMobile
