import React, { useContext, useEffect } from 'react'
import { Button } from '../../baseComponents'
import { configurationContext, myAccountContext, pathContext } from '../../../contexts/contexts'
import { useApiClient } from '../../../hooks/useApiClient'

const LegalRepresentative = () => {
  const { setUser } = useContext(myAccountContext)
  const { setUrl } = useContext(pathContext)
  const { configuration, setConfiguration } = useContext(configurationContext)

  const apiClient = useApiClient()

  const handleSubmit = (e: any) => {
    apiClient
      .post(
        '/users/set_account_type',
        { account_type: 'representative' }
      )
      .then(res => {
        setUser(res.data)
        setConfiguration({ ...configuration, accountTypeNeeded: false })
        //In the future we should be sending them directly to the dashboard and let redirection handle navigation.
        setUrl('/')
      })
      .catch(err => {
        console.log(err.response)

      })
  }

  useEffect(() => { 
      document.title = "USCIS Online Account | Confirm that you are a representative"
   },[])

  return (
    <div className='card' data-testid="confirm-representative-container">
      <h1 className="card-header text-left ">Confirm that you are a representative</h1>
      <div className='mx-16 border border-y-2 border-gray-200 opacity-30'></div>
      <div className="card-body p-0 flex flex-col space-y-6 text-md font-light my-4">
        <h2 className="text-xl mb-3 font-normal">A representative must be:</h2>
        <h3 className='text-lg'>An attorney</h3>
        <p>I am a person who is eligible to practice law in, who is in good standing of the bar of the highest courts of any U.S. state, possession, territory, commonwealth, or the District of Columbia, and I am not under any order restricting me from the practice of law. (See <a className='nav-link px-0' rel="noreferrer" target="_blank" href="https://www.ecfr.gov/current/title-8/chapter-I/subchapter-B/part-292#292.1">8 CFR 292.1</a> for additional information.)</p>
        <h3 className='text-lg'>An accredited representative</h3>
        <p>I am a person who is a representative of a qualified nonprofit religious, charitable, social service, or similar organization established in the United States, and who is recognized by the Department of Justice. (See <a className='nav-link px-0' rel="noreferrer" target="_blank" href="https://www.ecfr.gov/current/title-8/chapter-I/subchapter-B/part-292#292.1">8 CFR 292.1</a> and <a className='nav-link px-0' rel="noreferrer" target="_blank" href="https://www.ecfr.gov/current/title-8/chapter-V/subchapter-B/part-1292#sg8.1.1292_16.sg1">8 CFR 1292.11-1292.20</a> for additional information.)</p>

      </div>
      <div className='card-body mb-4 px-0 flex flex-col space-y-3 sm:flex-row sm:space-x-4 sm:space-y-0 '>
        <Button
          id="appmodal-2fa-btn"
          data-testid="test-appmodal-2fa-btn"
          text="I am a representative"
          className='max-w-full px-3 py-2 text-sm'
          onClick={handleSubmit}
        />
        <Button
          id="representative-back-btn"
          text="I am not a representative"
          type='secondary'
          className='max-w-full text-sm'
          onClick={() => setUrl('/users/type')}
        />
      </div>

    </div>
  )
}

export default LegalRepresentative