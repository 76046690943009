import React, { useContext, useEffect, useRef, useState } from "react"
import { Button, Modal } from "../../../baseComponents"
import { pathContext, myAccountContext } from "../../../../contexts/contexts"
import { IDPCard } from "../IDPCard"
import { useApiClient } from '../../../../hooks/useApiClient'

export const IDPQuestion = () => {
  const { setUrl } = useContext(pathContext)
  const { setUser, user } = useContext(myAccountContext)
  const apiClient = useApiClient()

  const handleCancel = () => {
    setUser({ ...user, ial_state: 'canceled_quiz' })
  }

  const initialQuestionData = {
    body: {
      question: {
        questionId: "",
        text: "",
        answers: [],
        helpImageUrl: "",
        helpMessage: "",
        ttl: "",
      },
    },
    success: "",
    status: "",
    message: "",
  }

  const [questionData, setQuestionData] = useState(initialQuestionData)
  const [showErrorView, setShowErrorView] = useState(false)
  const [imageLoaded, setImageLoaded] = useState(false)
  const [helpImg, setHelpImg] = useState(new Image())

  const [loadData, setLoadData] = useState(true)
  const [dataLoaded, setDataLoaded] = useState(false)
  const [nextButtonDisabled, setNextButtonDisabled] = useState(true)
  const [chosenAnswer, setChosenAnswer] = useState<string | null>(null)
  const [helpLabelClicked, setHelpLabelClicked] = useState(false)

  const imgRef = useRef<any>(null)

  useEffect(() => {
    if (!imgRef.current || !helpImg.src) return
    imgRef.current.appendChild(helpImg)
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [helpLabelClicked, helpImg.src, imageLoaded])

  useEffect(() => {
    if (!dataLoaded) return

    if (
      user.ial_state === "failed" ||
      user.ial_state === "passed" ||
      user.ial_state === "hard_fail"
    ) {
      setUrl("/idp/results")
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user.ial_state, dataLoaded])

  const handleSubmitAnswer = () => {
    const url = window.location.origin + "/v1/idpaas/check_answer"
    apiClient
      .post(url, {
        question_id: questionData.body.question.questionId,
        answer: chosenAnswer,
      })
      .then((res) => {
        setUser(res.data)
        setQuestionData(res.data.quiz_question)
      })
      .catch((err) => {
        setShowErrorView(true)
      })

    setQuestionData(initialQuestionData)
    setChosenAnswer(null)
    setNextButtonDisabled(true)
  }

  const handleResumeQuiz = () => {
    setShowErrorView(false)
    setLoadData(true)
  }

  useEffect(() => {
    if (loadData) {
      setLoadData(false)
      const url = window.location.origin + "/v1/idpaas/first_question"
      apiClient
        .get(url)
        .then((res) => {
          setUser(res.data)
          setQuestionData(res.data.quiz_question)
          setDataLoaded(true)
        })
        .catch((err) => {
          setShowErrorView(true)
        })
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadData])

  useEffect(() => {
    if (!questionData?.body?.question?.helpImageUrl) return
    const img = new Image()
    img.onload = () => {
      setHelpImg(img)
      setImageLoaded(true)
    }
    img.src = questionData?.body?.question?.helpImageUrl
    img.alt = questionData?.body?.question?.helpMessage
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [questionData?.body?.question?.helpImageUrl])

  return showErrorView ? (
    <IDPCard title="Resume your identity quiz?">
      <p className="idp-index-paragraph">
        We were not able to retrieve your question. Would you like to try again?
      </p>
      <h2 className="text-xl">Are you ready to start?</h2>
      <div className="justify-start flex space-x-4 pt-6 pb-0">
        <Button id="submit-btn" text="Resume Quiz" onClick={handleResumeQuiz} />
        <Button
          id="submit-btn"
          text="Cancel"
          type="secondary"
          onClick={handleCancel}
        />
      </div>
    </IDPCard>
  ) : questionData?.body?.question?.text ? (
    <>
      <IDPCard title={questionData.body.question.text}>
        {questionData.body.question.helpMessage && (
          <>
            <button
              data-testid="help-label-button"
              onClick={() => setHelpLabelClicked(true)}
              className="text-dhs_light_blue hover:text-dhs_focus_blue focus:ring-1 focus:ring-dhs_focus_blue hover:underline font-bold mt-1 mr-auto"
            >
              Where do I find this?
            </button>
            <div>
              <Modal
                visible={helpLabelClicked && imageLoaded}
                title={"Where to find this information?"}
                info={false}
                onCancel={() => setHelpLabelClicked(false)}
                hideCancelButton={true}
                hideOkButton={true}
              >
                <div data-testid="where-can-i-find-this-modal">
                  <div ref={imgRef}></div>
                  <div>
                    <p className="mx-auto">
                      {questionData?.body?.question?.helpMessage}
                    </p>
                  </div>
                </div>
              </Modal>
            </div>
          </>
        )}

        {questionData?.body?.question?.answers.map((choice: any) => (
          <div
            className="justify-start flex space-x-2 pb-0"
            key={`answer-choice-${choice.key}`}
          >
            <input
              data-testid={`answer-choice-${choice.key}-radio-button`}
              type="radio"
              aria-label={choice.text}
              name="answer-choice"
              value={choice.key}
              checked={chosenAnswer === choice.key}
              tabIndex={0}
              onChange={() => {
                setChosenAnswer(choice.key)
                setNextButtonDisabled(false)
              }}
            />
            <p>{choice.text}</p>
          </div>
        ))}
        <div className="justify-start flex space-x-4 pt-6 pb-0">
          <Button
            id="submit-answer-btn"
            text="Next"
            onClick={handleSubmitAnswer}
            disabled={nextButtonDisabled}
          />
        </div>
      </IDPCard>
    </>
  ) : null
}
