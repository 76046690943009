import React, { ChangeEventHandler, forwardRef, useContext } from 'react'
import './validated_input.css'
import { InputMask, Modify } from '@react-input/mask'
import { configurationContext } from '../../../contexts/contexts'
interface ValidatedInputProps {
  label: string | null
  msg: string | null
  onChange: ChangeEventHandler<HTMLInputElement>
  value: string
  type: string
  required?: boolean
  onKeyDown?: any
  hideLabel?: boolean
  disabled?: boolean
  readOnly?: boolean
  additionalStyling?: string
  arialabel?: string
  inputMode?: 'email' | 'numeric' | 'text'
  mobile?: boolean
  id?: string
}
// For unit testing purposes, a data-testid value is created from the label property.
// The label is changed to lower case and spaces are replaced with a dash, then this string is prepended with 'test-'
//    Example:  label="Email Address",  data-testid="test-email-address"
const ValidatedInput = (props: ValidatedInputProps, ref: any) => {
  const {
    label,
    msg,
    onChange,
    value,
    type,
    required = false,
    onKeyDown = () => {},
    hideLabel = false,
    disabled = false,
    readOnly = false,
    additionalStyling = '',
    arialabel = undefined,
    inputMode = 'text',
    mobile = false,
  } = props

  const { configuration } = useContext(configurationContext)
  const { mobileView } = configuration

  const id = label?.replaceAll(' ', '-').toLowerCase()
  const ariaLabel =
    arialabel ||
    `${label} ${required ? 'required' : ''} ${msg} ${
      type === 'text' ? value : '*'.repeat(value.length)
    }`

  const modify: Modify = (input) => {
    if (['0', '1', '_'].includes(input[0])) {
      return { mask: '(___) ___-____', replacement: { _: /[2-9]/ } }
    }
    return { mask: '(___) ___-____', replacement: { _: /\d/ } }
  }

  return (
    <div
      className={
        msg !== ''
          ? 'sticky w-full border-l-4 pl-2 border-dhs-error-red font-semibold'
          : 'w-full h-20 font-semibold'
      }
    >
      {!hideLabel && (
        <label
          htmlFor={id}
          className="text-md font-semibold"
          data-testid="validated-input-label"
        >
          {label}
          {required && <span className="text-dhs-error-red pl-1">*</span>}
        </label>
      )}
      {msg !== '' && (
        <div className="text-dhs-error-red m-1 p-0" data-testid="error-message">
          {msg}
        </div>
      )}
      {mobile ? (
        <div
          className={
            msg !== ''
              ? 'border-3 border-dhs-error-red rounded focus:outline-5 focus:ring-4'
              : 'my-2'
          }
        >
          <InputMask
            mask="(___) ___-____"
            replacement={{ _: /\d/ }}
            showMask={true}
            onChange={onChange}
            value={value}
            modify={modify}
            ref={ref}
            separate={true}
            id={id}
            type={mobileView ? 'tel' : ''}
            inputMode={inputMode}
            data-testid={`test-${id}`}
            className={`w-full px-2 py-1 text-xl ${
              msg !== '' ? 'outline-none' : 'validated_input'
            } ${additionalStyling}`}
            aria-label={ariaLabel}
          />
        </div>
      ) : (
        <div
          className={
            msg !== ''
              ? 'border-3 border-dhs-error-red rounded focus:outline-5 focus:ring-4'
              : 'my-2'
          }
        >
          <input
            data-testid={`test-${id}`}
            ref={ref}
            id={id}
            aria-label={ariaLabel}
            onChange={onChange}
            onKeyDown={onKeyDown}
            className={`w-full px-2 py-1 text-xl ${
              msg !== '' ? 'outline-none' : 'validated_input'
            } ${additionalStyling}`}
            type={type}
            inputMode={inputMode}
            value={value}
            disabled={disabled}
            readOnly={readOnly}
          />
        </div>
      )}
    </div>
  )
}
export default forwardRef(ValidatedInput)
