import React, { useRef, useState, useEffect, useContext } from 'react'
import { Button, SectionalAlert, ValidatedInput } from '../../baseComponents'

import { useApiClient } from '../../../hooks/useApiClient'
import { configurationContext, myAccountContext, pathContext } from '../../../contexts/contexts'
import { LegalSection } from '../../LegalSection/LegalSection'
import { validateEmailFormat } from '../../../utils/utils'

export const SUCCESS_ALERT_MSG = "If your email address exists in our database, you will receive an email with instructions for how to confirm your email address in a few minutes."

export const ResendConfirmation = () => {
  const { alert, setAlert, clearAlert } = useContext(myAccountContext)
  const { setUrl } = useContext(pathContext)
  const { configuration } = useContext(configurationContext)
  const { mobileView } = configuration
  const alertRef = useRef<HTMLDivElement>(null)

  const [emailInputVal, setEmailInputVal] = useState('')
  const [validationMsg, setValidationMsg] = useState('')
  const [submitSignin, setSubmitSignIn] = useState(false)
  const [disabledSubmit, setDisabledSubmit] = useState(false)

  const emailRef = useRef<HTMLInputElement>(null)

  const apiClient = useApiClient()

  const handleInputChange = (e: any) => {
    clearAlert()
    setValidationMsg('')
    setEmailInputVal(e.target.value)
    setDisabledSubmit(false)
  }

  const validateEmail = () => {
    if (emailInputVal === "") {
      setValidationMsg("Please enter a valid email address")
    } else {
      setValidationMsg(validateEmailFormat(emailInputVal.trim(), true))
    }
  }

  const handleSubmit = () => {
    setDisabledSubmit(true)
    validateEmail()
    setSubmitSignIn(true)
  }

  const handleEnterDown = (e: any) => {
    if (e.key === "Enter") {
      handleSubmit()
    }
  }

  useEffect(() => {
    if (submitSignin) {
      if (validationMsg !== '') {
        emailRef.current!.focus()
      } else {
        apiClient
          .post(
            '/users/resend_confirmation_email',
            { email: emailInputVal.trim() }
          )
          .then((res) => {
            setAlert({
              message: SUCCESS_ALERT_MSG,
              type: 'success',
              shouldPersist: true
            })
            setUrl('/sign-in')
          })
          .catch((error) => {
            setAlert({
              message: SUCCESS_ALERT_MSG,
              type: 'success',
              shouldPersist: true
            })
            setUrl('/sign-in')
          })
      }
      setSubmitSignIn(false)
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [submitSignin, emailInputVal, validationMsg])

  return (
    <div className="card mx-auto" data-testid="resend-confirmation-container">
      <h1 className="text-2xl my-3 font-normal text-dhs_font_gray">
        Resend Confirmation Instructions
      </h1>
      {alert.message !== '' && (
        <div
          data-testid="sectional-alert-container"
          ref={alertRef}
          tabIndex={0}
        >
          <SectionalAlert type={alert.type}>{alert.message}</SectionalAlert>
        </div>
      )}
      <div data-testid="email-input" className="my-3 pt-3">
        <ValidatedInput
          label="Email Address"
          required
          msg={validationMsg}
          onChange={handleInputChange}
          value={emailInputVal}
          type="text"
          inputMode="email"
          ref={emailRef}
          onKeyDown={handleEnterDown}
        />
      </div>

      <div className="button-container mt-8 mb-6">
        <Button
          id="submit-btn"
          text="Submit"
          disabled={disabledSubmit}
          onMouseDown={handleSubmit}
          onKeyDown={handleEnterDown}
        />
      </div>
      <div className="flex flex-col">
        <p>
          Return to
          <a
            href='/sign-in'
            data-testid="sign-in-link"
            onClick={(e) => {
              e.preventDefault()
              clearAlert()
              setUrl('/sign-in')
            }}
            aria-label="return to sign in"
            className="pl-1 text-dhs_light_blue hover:text-dhs_focus_blue hover:underline focus:ring-1 focus:ring-dhs_focus_blue no-underline font-bold mt-1 mr-auto"
          >
            Sign In
          </a>
        </p>
      </div>
      {mobileView ? null : (
        <>
          <div className="bg-gray-200 h-px max-w-full mt-4" />
          <LegalSection />
        </>
      )}
    </div>
  )
}